import React from "react";
import styles from "./ServiceFeatures.module.css";

const FeatureColumn = ({ features }) => {
  const firstRow = features.slice(0, 5);
  const secondRow = features.slice(5);

  return (
    <div className={styles.featureColumn}>
      <div className={styles.featureRow}>
        {firstRow.map((feature, index) => (
          <div key={index} className={styles.featureItem}>
            {feature}
          </div>
        ))}
      </div>
      {secondRow.length > 0 && (
        <div className={styles.featureRow}>
          {secondRow.map((feature, index) => (
            <div key={index} className={styles.featureItem}>
              {feature}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FeatureColumn;

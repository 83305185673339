import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom/dist";
import "./App.css";
import Home from "./Components/Home";
// import AboutUs from "./Components/About";
import ContactUs from "./Components/Contact";
import Navbar from "./Components/Navbar/Navbar";
import { ChakraProvider } from "@chakra-ui/react";
import CustomerSignupin from "./Components/CustomerSignupin";
import CustomerDashboard from "./Pages/Customer/CustomerDashboard";
import DoctorAppointment from "./Pages/Customer/Components/Services/DoctorAppointment";
import PetGromming from "./Pages/Customer/Components/Services/PetGromming";
import Mating from "./Pages/Customer/Components/Services/Mating";
import DayCareService from "./Pages/Customer/Components/Services/DayCareService";
import PetPedia from "./Pages/Customer/Components/Services/PetPedia";
import LocationsForPets from "./Pages/Customer/LocationsForPets";
import MyPets from "./Pages/Customer/MyPets";
import Services from "./Pages/Customer/Components/Services/Services";
import PetDiary from "./Pages/Customer/PetDiary";
import UserProfilePage from "./Pages/Customer/UserProfilePage";
import ServiceProviderSignupin from "./Components/ServiceProviderSignupin";
import GroomingServiceDashboard from "./Pages/GroomingServices/GroomingServiceDashboard";
import TotalAppointmentsSP from "./Pages/GroomingServices/TotalAppointmentsSP";
import InvoiceSearch from "./Pages/GroomingServices/InvoiceSearch";
import TakeLeave from "./Pages/GroomingServices/TakeLeave";
import DoctorSignupin from "./Components/DoctorSignupin";
import DoctorDashboard from "./Pages/Doctor/DoctorDashboard";
import MedicineInventory from "./Pages/Doctor/MedicineInventory";
import VerticalCarousel from "./Components/Services2";
import Hero from "./Components/Hero/Hero";
import AboutUs from './Components/About/AboutUs'; 
import BookaVet from "./Pages/Services/BookaVet";



const App = () => {
  return (
    <>
      <ChakraProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Navbar />}>
              {/* <Route index element={<Home />} /> */}
            </Route>
            {/* <Route path="/about" element={<AboutUs />} /> */}
            <Route path="/contactUs" element={<ContactUs />} />
            {/* <Route path="/contact" element={<VerticalCarousel />} /> */}
            <Route path="/c_login" element={<CustomerSignupin />} />
            <Route path="/customerDashboard" element={<CustomerDashboard />} />
            <Route path="services" element={<Services/>}/>
            <Route path="/d_login" element={<DoctorSignupin />} />
            <Route path="/doctorAppointment" element={<DoctorAppointment />} />
            <Route path="/petGromming" element={<PetGromming />} />
            <Route path="/dayCare" element={<DayCareService />} />
            <Route path="/petMating" element={<Mating />} />
            <Route path="/petPedia" element={<PetPedia />} />
            <Route path="/locationsForPets" element={<LocationsForPets />} />
            <Route path="/myPets" element={<MyPets />} />
            <Route path="/petDiary" element={<PetDiary />} />
            <Route path="/userProfile" element={<UserProfilePage />} />
            <Route path="/sp_login" element={<ServiceProviderSignupin />} />
            <Route path="/hero" element={<Hero />} />
            <Route path="/about" element={<AboutUs />} />
            <Route path="/book-a-vet" element={<BookaVet/>} />
            <Route
              path="/servicesAgentDashboard"
              element={<GroomingServiceDashboard />}
            />
            <Route
              path="/totalAppointmentsSP"
              element={<TotalAppointmentsSP />}
            />
            <Route path="/searchInvoice" element={<InvoiceSearch />} />
            <Route path="/checkLeaves" element={<TakeLeave />} />
            <Route path="/doctorDashboard" element={<DoctorDashboard />} />
            <Route path="/medicineInventory" element={<MedicineInventory />} />
          </Routes>
        </BrowserRouter>
      </ChakraProvider>
    </>
  );
};

export default App;

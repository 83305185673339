import React, { useState } from "react";
import styles from "./ServiceFeatures.module.css";
import FeatureColumn from "./FeatureColumn";

const ServiceFeatures = () => {
  const servicesData = {
    Features: {
      subServices: ["E-Report", "Pet Diary", "Location", "Ride"],
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20",
    },
    Services: {
      subServices: ["Grooming", "Day Care", "Tinder", "Mating", "Pet Sitter / Walker","Events","Training","Insurance", "Cremation","Nutrition Diet"],
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20", 
    },
    "Social Services": {
      subServices: ["Blood Bank", "Found & Missing", "Adoption", "Pet Breeds", "Pet & Animal NGOs"],
      image: "https://cdn.builder.io/api/v1/image/assets/TEMP/5d5bd34c6b1efe6bedd761be917d8c9c9e38d2b4bbf866b8efcb94b92f3cb370?placeholderIfAbsent=true&apiKey=860967788028437b8a0095d5a96bdd20", 
    },
  };

  const [selectedService, setSelectedService] = useState("Features");

  const handleServiceChange = (service) => {
    setSelectedService(service);
  };

  return (
    <section className={styles.container}>
      <div className={styles.content}>
        <div className={styles.featureSelector}>
          {Object.keys(servicesData).map((service, index) => (
            <div
              key={index}
              className={`${styles.mainService} ${
                selectedService === service ? styles.selectedService : ""
              }`}
              onClick={() => handleServiceChange(service)}
            >
              {service}
            </div>
          ))}
        </div>
        {/* <div className={styles.divider}></div> */}
        <div className={styles.subServices}>
          <FeatureColumn features={servicesData[selectedService].subServices} />
        </div>
        <div>
        <img
          src={servicesData[selectedService].image}
          alt={`${selectedService} illustration`}
          className={styles.featureImage}
          loading="lazy"
        />
        </div>
        
      </div>
    </section>
  );
};

export default ServiceFeatures;

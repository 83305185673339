import React, { useEffect, useState } from "react";
import "./Services2.css";

const VerticalCarousel = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const carousel = document.querySelector(".carousel");
      if (carousel) {
        const proportion =
          carousel.getBoundingClientRect().top / window.innerHeight;
        const index = Math.ceil(-1 * (proportion + 0.5));
        setActiveIndex(index);
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="center">
      <div className="top">Solar System</div>
      <div className="carousel">
        <div className="left">
          <div className={`left-item ${activeIndex === 0 ? "active" : ""}`}>
            <div className="title">Sun</div>
            <div className="text">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Commodi
              quisquam eaque, laboriosam expedita perferendis facilis esse
              deleniti qui voluptas! Nesciunt!
            </div>
          </div>
        </div>
        <div className="right">
          <div className={`item ${activeIndex === 0 ? "active" : ""}`}>
            <img src="../images/sun-image.png" alt="" />
            <button type="button">View More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerticalCarousel;
